import React from "react";
import Select from "components/Select/Select";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Dropzone from "components/Dropzone";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import MultiSelect from "@mui/material/Select";
import Input from '@mui/material/Input';
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";

const filter = createFilterOptions();

const LinearDeterminate = ({ uploadPercentage }) => {
  return (
    <div>
      <LinearProgress variant="determinate" value={uploadPercentage.percent} />
      <Typography variant="caption" component="div" color="textSecondary">{uploadPercentage ? `${Math.round(
        uploadPercentage.percent,
      )}% Please wait for the upload to complete.` : ""}</Typography>
    </div>
  );
}


const AddUpdateTicket = (updateActivityFieldHandler, updateTicketDatabase, CRMTicketPriorites, CRMTicketTypes,
  CRMPeople, TicketsubmissionData, CRMAssignedToUsers, updatePeopleFieldHandler, B2B, CRMTicketStatuses,
  updatePeopleNameFieldHandler, updateTicketFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
  customer_id, Update = false, CRMActivity = [], CRMPhotoGallery, CRMContract, CRMProposal, CRMJob, CRMDocument,
  CRMEstimate, CRMProducts, CRMServices) => {
  if (CRMTicketPriorites && TicketsubmissionData?.priorities && typeof TicketsubmissionData?.priorities === 'string') {
    let CC_Users_Data = [];
    TicketsubmissionData?.cc_users?.split(",")?.forEach(async (SelectedUser) => {
      CRMAssignedToUsers?.forEach(async (CurrentCRMAssignedToUser) => {
        if (SelectedUser?.first_name + " " + SelectedUser?.last_name === CurrentCRMAssignedToUser?.first_name + " " + CurrentCRMAssignedToUser?.last_nam) {
          CC_Users_Data.push(CurrentCRMAssignedToUser?.first_name + " " + CurrentCRMAssignedToUser?.last_nam);
        }
      })
    })

    if (CC_Users_Data?.length) {
      TicketsubmissionData.cc_users = CC_Users_Data;
    }
  }

  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>{Update ? "Update" : "Create A"} Ticket</label>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", whiteSpace: "nowrap", width: "250px" }}>Ticket Name:</label>
        <input
          id="name"
          maxLength={255}
          type="text"
          value={TicketsubmissionData?.name || ""}
          onChange={updateTicketFieldHandler("name")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "250px" }}>Ticket Priority:</label>
        <Select
          options={CRMTicketPriorites?.map(({ id, priority }) => ({
            key: id,
            label: priority
          }))}
          selected={TicketsubmissionData?.priority || ""}
          onSelect={updateTicketFieldHandler("priority")}
          style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ float: "left", width: "250px" }}>Requested Completion Date:</label>
        <TextField
          id="requested_completion_date"
          type="datetime-local"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={TicketsubmissionData?.requested_completion_date?.indexOf("-") === -1 ? moment(parseInt(TicketsubmissionData?.requested_completion_date)).format("YYYY-MM-DDTHH:mm") : TicketsubmissionData?.requested_completion_date ? TicketsubmissionData?.requested_completion_date : ""}
          onChange={
            updateTicketFieldHandler("requested_completion_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "250px" }}>Ticket Type:</label>
        <Select
          options={CRMTicketTypes?.map(({ id, type }) => ({
            key: id,
            label: type
          }))}
          selected={TicketsubmissionData?.type || ""}
          onSelect={updateTicketFieldHandler("type")}
          style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px", display: "flex" }}>
        <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Status:</label>
        <Select
          options={CRMTicketStatuses?.map(({ id, status }) => ({
            key: id,
            label: status
          }))}
          selected={TicketsubmissionData?.status || 0}
          onSelect={updateTicketFieldHandler("status")}
          style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {uploadPercentage && (
        <LinearDeterminate uploadPercentage={uploadPercentage} />
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px", display: "flex" }}>
        <label style={{ textAlign: "left", float: "left", width: "250px" }}>Upload Ticket Files:</label>
        <div style={{ width: "100%", height: "40px !important", float: "left" }}>
          <Dropzone
            setuploadPercentage={setuploadPercentage}
            path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
            onChange={(value) => { updateTicketFieldHandler("file_url")({ target: { value } }) }}
            value={TicketsubmissionData["file_url"] || ""}
            multiple={true}
            maxFiles={100}
          >
            {() => {
              return (
                <div style={{ width: "100%", height: "300px", borderColor: "grey", borderWidth: "1px", borderStyle: "solid", textAlign: "center", verticalAlign: "middle", lineHeight: "300px", borderRadius: "5px" }}>
                  <Button
                    style={{ backgroundColor: "#0071ce", color: "#ffffff" }}
                  >
                    Upload file(s)
                  </Button>
                  <span> ...or drag file(s) here.  You must save to upload photos.</span>
                </div>
              )
            }}</Dropzone>
        </div>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px", display: "flex" }}>
        <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Assign To:</label>
        <Select
          options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
            key: id,
            label: first_name + " " + last_name
          }))}
          selected={TicketsubmissionData?.assigned_to || 0}
          onSelect={updateTicketFieldHandler("assigned_to")}
          style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px", display: "flex" }}>
        <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Cc Users:</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateTicketFieldHandler("cc_users")}
          inputProps={{
            name: "cc_users",
            id: "cc_users",
            value: (typeof TicketsubmissionData?.cc_users === 'string' || TicketsubmissionData?.cc_users instanceof String) ? TicketsubmissionData?.cc_users?.split(",").map((cc_user) => { return cc_user }) || [] : (typeof TicketsubmissionData?.cc_users !== 'undefined') ? TicketsubmissionData?.cc_users || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMAssignedToUsers?.map((CurrentCRMAssignedToUser) => {
            var checked = false;
            if ((typeof TicketsubmissionData?.cc_users === 'string' || TicketsubmissionData?.cc_users instanceof String)) {
              var temp = TicketsubmissionData?.cc_users?.split(",");

              for (var index = 0; index <= temp?.length; index++) {
                if (temp[index]?.toString() === CurrentCRMAssignedToUser?.id?.toString() || temp[index]?.toString() === CurrentCRMAssignedToUser?.first_name + " " + CurrentCRMAssignedToUser?.last_name) { checked = true }
              }
            }
            if (TicketsubmissionData?.cc_users instanceof Object) {
              for (var index = 0; index <= TicketsubmissionData?.cc_users?.length; index++) {
                if (TicketsubmissionData?.cc_users[index]?.toString() === CurrentCRMAssignedToUser?.first_name + " " + CurrentCRMAssignedToUser?.last_name || TicketsubmissionData?.cc_users[index]?.toString() === CurrentCRMAssignedToUser?.id?.toString()) { checked = true }
              }
            }
            return (
              <MenuItem key={CurrentCRMAssignedToUser?.id} value={CurrentCRMAssignedToUser?.first_name + " " + CurrentCRMAssignedToUser?.last_name}>
                <CustomCheckbox
                  checked={checked}
                />
                <ListItemText primary={CurrentCRMAssignedToUser?.first_name + " " + CurrentCRMAssignedToUser?.last_name} />
              </MenuItem>
            )
          })}
        </MultiSelect></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "250px" }}>Service(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateTicketFieldHandler("services")}
          inputProps={{
            name: "services",
            id: "services",
            value: (typeof TicketsubmissionData?.services === 'string' || TicketsubmissionData?.services instanceof String) ? TicketsubmissionData?.services?.split(",").map((category_text) => { return category_text }) || [] : (typeof TicketsubmissionData?.services !== 'undefined') ? TicketsubmissionData?.services || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMServices?.map((TicketsubmissionDataService) => {
            return (
              <MenuItem key={TicketsubmissionDataService?.id} value={TicketsubmissionDataService?.service_name}>
                <CustomCheckbox
                  checked={
                    TicketsubmissionData?.services?.indexOf(TicketsubmissionDataService?.service_name) >
                    -1
                  }
                />
                <ListItemText primary={TicketsubmissionDataService?.service_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "250px" }}>Product(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateTicketFieldHandler("products")}
          inputProps={{
            name: "products",
            id: "products",
            value: (typeof TicketsubmissionData?.products === 'string' || TicketsubmissionData?.products instanceof String) ? TicketsubmissionData?.products?.split(",").map((product_name) => { return product_name }) || [] : (typeof TicketsubmissionData?.products !== 'undefined') ? TicketsubmissionData?.products || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMProducts?.map((TicketsubmissionDataProduct) => {
            return (
              <MenuItem key={TicketsubmissionDataProduct?.id} value={TicketsubmissionDataProduct?.product_name}>
                <CustomCheckbox
                  checked={
                    TicketsubmissionData?.products?.indexOf(TicketsubmissionDataProduct?.product_name) >
                    -1
                  }
                />
                <ListItemText primary={TicketsubmissionDataProduct?.product_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {B2B === "B2C" && (
        <>
          <div style={{ marginTop: "20px" }}>
            <label
              style={{ marginTop: "8px", float: "left", width: "250px" }}>People:</label>
            <Stack spacing={2}>
              <Autocomplete
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableClearable
                id="people_name"
                style={{ float: "left", borderRadius: "5px", width: "100%" }}
                value={CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === TicketsubmissionData?.people_id)?.map((CurrentPerson) => {
                  return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                })[0] || { label: TicketsubmissionData?.people_name ? TicketsubmissionData?.people_name : "" }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    updatePeopleNameFieldHandler(newValue.label, "Job")
                  } else if (newValue) {
                    updatePeopleNameFieldHandler(newValue.label, "Job")
                  }
                }}
                options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                  key: id,
                  label: first_name + " " + last_name
                })) || []}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;

                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Regular option
                  return option?.label;
                }}
                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { padding: 0, width: "100%" }
                    }}
                    onChange={updatePeopleFieldHandler("people_name", "Job")}
                    maxLength={255}
                  />
                )}
              />
            </Stack>
          </div>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
        </>
      )}
      {
        PersonFound ? "" :
          <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>
      }
      {CRMJob?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Job:</label>
            <Select
              options={CRMJob?.map(({ id, job_name }) => ({
                key: id,
                label: job_name
              }))}
              selected={TicketsubmissionData?.job_id || 0}
              onSelect={updateTicketFieldHandler("job_id")}
              style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
            /></div>
        </>
      )}
      {CRMPhotoGallery?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Gallery:</label>
            <Select
              options={CRMPhotoGallery?.map(({ id, photo_gallery_name }) => ({
                key: id,
                label: photo_gallery_name
              }))}
              selected={TicketsubmissionData?.photo_gallery_id || 0}
              onSelect={updateTicketFieldHandler("photo_gallery_id")}
              style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
            /></div>
        </>
      )}
      {CRMDocument?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Document:</label>
            <Select
              options={CRMDocument?.map(({ id, documents_name }) => ({
                key: id,
                label: documents_name
              }))}
              selected={TicketsubmissionData?.document_id || 0}
              onSelect={updateTicketFieldHandler("document_id")}
              style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
            /></div>
        </>
      )}
      {CRMEstimate?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Estimate:</label>
            <Select
              options={CRMEstimate?.map(({ id, estimate_name }) => ({
                key: id,
                label: estimate_name
              }))}
              selected={TicketsubmissionData?.estimate_id || 0}
              onSelect={updateTicketFieldHandler("estimate_id")}
              style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
            /></div>
        </>
      )}
      {CRMContract?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Contract:</label>
            <Select
              options={CRMContract?.map(({ id, contract_name }) => ({
                key: id,
                label: contract_name
              }))}
              selected={TicketsubmissionData?.contract_id || 0}
              onSelect={updateTicketFieldHandler("contract_id")}
              style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
            /></div>
        </>
      )}
      {CRMProposal?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ marginTop: "8px", width: "250px", float: "left" }}>Proposal:</label>
            <Select
              options={CRMProposal?.map(({ id, proposal_name }) => ({
                key: id,
                label: proposal_name
              }))}
              selected={TicketsubmissionData?.proposal_id || 0}
              onSelect={updateTicketFieldHandler("proposal_id")}
              style={{ marginBottom: 20, float: "left", height: "40px", width: "100%" }}
            /></div>
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar src="https://adreports-assets.s3.us-west-2.amazonaws.com/avatars/101" />
          </ListItemAvatar>
          <ListItemText
            primary="Brunch this weekend?"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: 'text.primary', display: 'inline' }}
                >
                  <b>Andrew Kloos</b>
                </Typography>
                {" — I'll be in your neighborhood doing errands this…"}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar src="https://adreports-assets.s3.us-west-2.amazonaws.com/avatars/7" />
          </ListItemAvatar>
          <ListItemText
            primary="Summer BBQ"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: 'text.primary', display: 'inline' }}
                >
                  <b>Nate Louis</b>
                </Typography>
                {" — Wish I could come, but I'm out of town this…"}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar src="https://adreports-assets.s3.us-west-2.amazonaws.com/customer_creatives/assets/38/bobthebuilder2jpg_1726158230852jpg" />
          </ListItemAvatar>
          <ListItemText
            primary="Oui Oui"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  sx={{ color: 'text.primary', display: 'inline' }}
                >
                  <b>Bob The Builder</b>
                </Typography>
                {' — Do you have Paris recommendations? Have you ever…'}
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
      <div style={{ clear: "both", marginTop: "20px" }}></div>

      <div><RichTextEditor key={CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === TicketsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === TicketsubmissionData?.id)[0]["activity_type"]?.length : null} current={CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === TicketsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.document_id === TicketsubmissionData?.id)[0] : null} updateFieldHandler={updateActivityFieldHandler} field={"activity_notes"} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px", width: "100%" }} /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
        updateTicketDatabase();
      }}>
        Save
      </Button>
    </div >
  )
};

export default AddUpdateTicket;
