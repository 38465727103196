import React from "react";
import Typography from '@mui/material/Typography';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import Tooltip from '@mui/material/Tooltip';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import LocalActivityOutlined from '@mui/icons-material/LocalActivityOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import { startCase } from "lodash";

const moment = require("moment");

import "assets/css/richtext_styles.css";

const LatestActivity = (NoteFilterImageSelected, CallFilterImageSelected, EmailFilterImageSelected, TextFilterImageSelected,
  MeetingFilterImageSelected, TaskFilterImageSelected, DeadlineFilterImageSelected, JobFilterImageSelected,
  PhotoFilterImageSelected, UploadFilterImageSelected, InboundFormsFilterImageSelected, OnboardingFilterImageSelected, setOnboardingFilterImageSelected,
  InboundCallsFilterImageSelected, ProposalFilterImageSelected, ContractFilterImageSelected, EstimateFilterImageSelected, setEstimateFilterImageSelected,
  setNoteFilterImageSelected, setJobFilterImageSelected, setUploadFilterImageSelected, setDeadlineFilterImageSelected,
  setTaskFilterImageSelected, setMeetingFilterImageSelected, setEmailFilterImageSelected,
  setTextFilterImageSelected, setPhotoFilterImageSelected, setContractFilterImageSelected, setProposalFilterImageSelected,
  setInboundCallsFilterImageSelected, setInboundFormsFilterImageSelected, setCallFilterImageSelected,
  ShownActivityCount, showMoreActivity, setshowMoreActivity, CRMActivity, users, CRMRepresentative, CRMContract, CRMProposal, CRMDocument,
  CRMPhotoGallery, CRMJob, customer_id, FormData, CallData, CRMPeople, CRMProducts, CRMServices, CRMEstimate) => {

  return (
    <div>
      <div style={{ clear: "both", height: "30px" }}></div>
      <label>Filter Activity Type:</label>
      <div style={{ clear: "both" }}></div>
      <div style={{ clear: "both", height: "30px" }}></div>
      <Tooltip title="Note" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (NoteFilterImageSelected) {
              setNoteFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setMeetingFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setNoteFilterImageSelected(true);
            }
          }}>
            <NoteOutlinedIcon style={NoteFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Call" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (CallFilterImageSelected) {
              setCallFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setMeetingFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setCallFilterImageSelected(true);
            }
          }}>
            <PhoneOutlinedIcon style={CallFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Email" placement="top">

        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (EmailFilterImageSelected) {
              setEmailFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setMeetingFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setEmailFilterImageSelected(true);
            }
          }}>
            <EmailOutlinedIcon style={EmailFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Text" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (TextFilterImageSelected) {
              setTextFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setMeetingFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setTextFilterImageSelected(true);
            }
          }}>
            <SmartphoneOutlinedIcon style={TextFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Meeting" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (MeetingFilterImageSelected) {
              setMeetingFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setMeetingFilterImageSelected(true);
            }
          }}>
            <GroupAddOutlinedIcon style={MeetingFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Task" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (TaskFilterImageSelected) {
              setTaskFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setMeetingFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setTaskFilterImageSelected(true);
            }
          }}>
            <TaskAltOutlinedIcon style={TaskFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Deadline" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (DeadlineFilterImageSelected) {
              setDeadlineFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setDeadlineFilterImageSelected(true);
            }
          }}>
            <MenuOutlinedIcon style={DeadlineFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Jobs" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (JobFilterImageSelected) {
              setJobFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setJobFilterImageSelected(true);
            }
          }}>
            <WorkOutlineOutlinedIcon style={JobFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Photos" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (PhotoFilterImageSelected) {
              setPhotoFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setJobFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setPhotoFilterImageSelected(true);
            }
          }}>
            <PhotoCameraOutlinedIcon style={PhotoFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Document" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (UploadFilterImageSelected) {
              setUploadFilterImageSelected(false);
            }
            else {
              setJobFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setUploadFilterImageSelected(true);
            }
          }}>
            <UploadFileOutlinedIcon style={UploadFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Estimate" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (EstimateFilterImageSelected) {
              setEstimateFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setJobFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setEstimateFilterImageSelected(true);
            }
          }}>
            <LocalActivityOutlined style={EstimateFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Proposal" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (ProposalFilterImageSelected) {
              setProposalFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setJobFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setProposalFilterImageSelected(true);
            }
          }}>
            <ArticleOutlinedIcon style={ProposalFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Contract" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (ContractFilterImageSelected) {
              setContractFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setJobFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setContractFilterImageSelected(true);
            }
          }}>
            <TaskOutlinedIcon style={ContractFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Inbound Calls" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (InboundCallsFilterImageSelected) {
              setInboundCallsFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setJobFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setInboundCallsFilterImageSelected(true);
            }
          }}>
            <ContactPhoneOutlinedIcon style={InboundCallsFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Inbound Forms" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (InboundFormsFilterImageSelected) {
              setInboundFormsFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setJobFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setOnboardingFilterImageSelected(false);
              setInboundFormsFilterImageSelected(true);
            }
          }}>
            <ContactMailOutlinedIcon style={InboundFormsFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <Tooltip title="Onboarding" placement="top">
        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
          <a onClick={(e) => {
            e.preventDefault();
            if (OnboardingFilterImageSelected) {
              setOnboardingFilterImageSelected(false);
            }
            else {
              setMeetingFilterImageSelected(false);
              setTaskFilterImageSelected(false);
              setTextFilterImageSelected(false);
              setEmailFilterImageSelected(false);
              setCallFilterImageSelected(false);
              setDeadlineFilterImageSelected(false);
              setUploadFilterImageSelected(false);
              setJobFilterImageSelected(false);
              setPhotoFilterImageSelected(false);
              setContractFilterImageSelected(false);
              setProposalFilterImageSelected(false);
              setInboundCallsFilterImageSelected(false);
              setNoteFilterImageSelected(false);
              setEstimateFilterImageSelected(false);
              setInboundFormsFilterImageSelected(false);
              setOnboardingFilterImageSelected(true);
            }
          }}>
            <FactCheckOutlinedIcon style={OnboardingFilterImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
          </a>
        </span>
      </Tooltip>
      <div style={{ clear: "both", height: "30px" }}></div>
      <div style={{ clear: "both", height: "30px" }}></div>
      <Timeline>
        {CRMActivity?.map((CurrentActivity, index) => {
          if (NoteFilterImageSelected && CurrentActivity?.activity_type !== "Note")
            return
          if (JobFilterImageSelected && CurrentActivity?.activity_type !== "Job")
            return
          if (UploadFilterImageSelected && CurrentActivity?.activity_type !== "Document")
            return
          if (DeadlineFilterImageSelected && CurrentActivity?.activity_type !== "Deadline")
            return
          if (TaskFilterImageSelected && CurrentActivity?.activity_type !== "Task")
            return
          if (MeetingFilterImageSelected && CurrentActivity?.activity_type !== "Meeting")
            return
          if (CallFilterImageSelected && CurrentActivity?.activity_type !== "Call")
            return
          if (TextFilterImageSelected && CurrentActivity?.activity_type !== "Text")
            return
          if (EmailFilterImageSelected && CurrentActivity?.activity_type !== "Email")
            return
          if (PhotoFilterImageSelected && CurrentActivity?.activity_type !== "Photo")
            return
          if (ContractFilterImageSelected && CurrentActivity?.activity_type !== "Contract")
            return
          if (EstimateFilterImageSelected && CurrentActivity?.activity_type !== "Estimate")
            return
          if (ProposalFilterImageSelected && CurrentActivity?.activity_type !== "Proposal")
            return
          if (InboundCallsFilterImageSelected && CurrentActivity?.activity_type !== "Inbound Call")
            return
          if (InboundFormsFilterImageSelected && CurrentActivity?.activity_type !== "Inbound Form")
            return
          if (OnboardingFilterImageSelected && CurrentActivity?.activity_type !== "Onboarding Sent to Customer" &&
            CurrentActivity?.activity_type !== "Customer Submitted Onboarding")
            return

          if (ShownActivityCount > 20 && showMoreActivity === false) {
            return null
          }
          else {
            ShownActivityCount++;

            return (
              <>
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    variant="body2"
                    color="#000"
                  >
                    {CurrentActivity?.activity_type === "Note" && (
                      <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                        {CurrentActivity?.activity_type}
                      </span>
                    )}
                    {CurrentActivity?.activity_type !== "Photo" &&
                      CurrentActivity?.activity_type !== "Job" &&
                      CurrentActivity?.activity_type !== "Contract" &&
                      CurrentActivity?.activity_type !== "Document" &&
                      CurrentActivity?.activity_type !== "Proposal" &&
                      CurrentActivity?.activity_type !== "Estimate" &&
                      CurrentActivity?.activity_type !== "Note" &&
                      CurrentActivity?.activity_type !== "Inbound Call" &&
                      CurrentActivity?.activity_type !== "Inbound Form" &&
                      CurrentActivity?.activity_type !== "Onboarding Sent to Customer" &&
                      CurrentActivity?.activity_type !== "Customer Submitted Onboarding" && (
                        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                          <a href={"/admin/crmactivitydetail?customer=" + customer_id + "&id=" + CurrentActivity?.crm_customers_id + "&peopleid=" + CurrentActivity?.people_id + "&activityid=" + CurrentActivity?.id}
                          >{CurrentActivity?.activity_description}</a>
                        </span>
                      )}
                    {CurrentActivity?.activity_type === "Photo" && (
                      CRMPhotoGallery?.filter((CurrenGallery) => CurrenGallery?.id === CurrentActivity?.photo_gallery_id)?.map(CurrenGallery => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}><a href={"/admin/crmphotodetail?customer=" + customer_id + "&id=" + CurrenGallery?.crm_customers_id + "&peopleid=" + CurrenGallery?.people_id + "&photoid=" + CurrenGallery?.id}
                          >{CurrenGallery?.photo_gallery_name}</a>
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Job" && (
                      CRMJob?.filter((CurrentJob) => CurrentJob?.id === CurrentActivity?.job_id)?.map(CurrentJob => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                            <a href={"/admin/crmjobdetail?customer=" + customer_id + "&id=" + CurrentJob?.crm_customers_id + "&peopleid=" + CurrentJob?.people_id + "&jobid=" + CurrentJob?.id}
                            >{CurrentJob?.job_name}</a>
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Document" && (
                      CRMDocument?.filter((CurrentDocument) => CurrentDocument?.id === CurrentActivity?.document_id)?.map(CurrentDocument => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                            <a href={"/admin/crmdocumentdetail?customer=" + customer_id + "&id=" + CurrentDocument?.crm_customers_id + "&peopleid=" + CurrentDocument?.people_id + "&documentid=" + CurrentDocument?.id}
                            >{CurrentDocument?.documents_name}</a>
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Contract" && (
                      CRMContract?.filter((CurrentContract) => CurrentContract?.id === CurrentActivity?.contract_id)?.map(CurrentContract => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                            <a href={"/admin/crmcontractdetail?customer=" + customer_id + "&id=" + CurrentContract?.crm_customers_id + "&peopleid=" + CurrentContract?.people_id + "&contractid=" + CurrentContract?.id}
                            >{CurrentContract?.contract_name}</a>
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Proposal" && (
                      CRMProposal?.filter((CurrentProposal) => CurrentProposal?.id === CurrentActivity?.proposal_id)?.map(CurrentProposal => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                            <a href={"/admin/crmproposaldetail?customer=" + customer_id + "&id=" + CurrentProposal?.crm_customers_id + "&peopleid=" + CurrentProposal?.people_id + "&proposalid=" + CurrentProposal?.id}
                            >{CurrentProposal?.proposal_name}</a>
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Estimate" && (
                      CRMEstimate?.filter((CurrentEstimate) => CurrentEstimate?.id === CurrentActivity?.estimate_id)?.map(CurrentEstimate => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                            <a href={"/admin/crmestimatedetail?customer=" + customer_id + "&id=" + CurrentEstimate?.crm_customers_id + "&estimateid=" + CurrentEstimate?.id}
                            >{CurrentActivity?.activity_description}</a>
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Onboarding Sent to Customer" && (
                      CRMContract?.filter((CurrentContract) => CurrentContract?.id === CurrentActivity?.contract_id)?.map(CurrentContract => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                            <a href={"/admin/onboardingform?customer=" + customer_id + "&id=" + CurrentActivity?.crm_customers_id + "&peopleid=" + CurrentActivity?.people_id + "&contractid=" + CurrentActivity?.contract_id + (CurrentActivity?.onboarding_id ? "&onboarding_id=" + CurrentActivity?.onboarding_id : "")}
                            >{CurrentActivity?.activity_description}</a>
                            <br />
                            <br />
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Customer Submitted Onboarding" && (
                      CRMContract?.filter((CurrentContract) => CurrentContract?.id === CurrentActivity?.contract_id)?.map(CurrentContract => {
                        return (
                          <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                            <a href={"/admin/onboardingform?customer=" + customer_id + "&id=" + CurrentActivity?.crm_customers_id + "&peopleid=" + CurrentActivity?.people_id + "&contractid=" + CurrentActivity?.contract_id + (CurrentActivity?.onboarding_id ? "&onboarding_id=" + CurrentActivity?.onboarding_id : "")}
                            >{CurrentActivity?.activity_description}</a>
                            <br />
                            <br />
                          </span>
                        )
                      }))}
                    {CurrentActivity?.activity_type === "Inbound Call" && (
                      <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                        {CurrentActivity?.activity_type}
                      </span>
                    )}
                    {CurrentActivity?.activity_type === "Inbound Form" && (
                      <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                        {CurrentActivity?.activity_type}
                      </span>
                    )}
                    <div style={{ clear: "both", height: "30px" }}></div>
                    {CurrentActivity?.activity_type === "Inbound Form" && FormData?.filter(CurrentForm => CurrentForm?.id === CurrentActivity?.activity_description?.split(":")[1])?.map(CurrentForm =>
                      <div style={{ textAlign: "left" }}>
                        <div style={{ clear: "both", height: "30px" }}></div>
                        <p><b>Name</b>: {CurrentForm?.formatted_customer_name}</p>
                        <p><b>Phone</b>: {CurrentForm?.formatted_customer_phone_number}</p>
                        <p><b>Submitted At</b>: {CurrentForm?.submitted_at ? moment(new Date(parseInt(CurrentForm.submitted_at))).format("YYYY-MM-DD hh:mm:ss a") : ""}</p>
                        <p><b>Source</b>: {CurrentForm?.source}</p>
                        <p><b>Form Url</b>: {CurrentForm?.form_url}</p>
                        <p><b>Landing Page</b>: {CurrentForm?.landing_page_url}</p>
                        <p><b>Value</b>: {CurrentForm?.value}</p>
                        <div>
                          {Object.keys(CurrentForm.form_data).map(key => {
                            const title = startCase(key.split("<")[0]);
                            if (title === "G Recaptcha Response") {
                              return null;
                            }
                            return (
                              <div
                                key={key}
                                style={{
                                  textAlign: "right",
                                  marginBottom: 5,
                                  display: "flex"
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: 10,
                                    lineHeight: "18px"
                                  }}
                                  title={title}
                                >
                                  <b>{title}</b>:&nbsp;
                                </div>
                                <div>
                                  {CurrentForm.form_data[key]?.length > 20 ? CurrentForm.form_data[key].substring(0, 19) + "..." : CurrentForm.form_data[key]}
                                </div>
                              </div>
                            );
                          })
                          }
                        </div>
                      </div>
                    )}
                    {CurrentActivity?.activity_type === "Inbound Call" && CallData?.filter(CurrentCall => CurrentCall?.id === CurrentActivity?.activity_description?.split(":")[1])?.map(CurrentCall =>
                      <div style={{ textAlign: "left" }}>
                        <div style={{ clear: "both", height: "30px" }}></div>
                        <p><b>Name</b>: {CurrentCall?.formatted_customer_name}</p>
                        <p><b>Phone</b>: {CurrentCall?.formatted_customer_phone_number}</p>
                        <p><b>Duration</b>: {CurrentCall?.formatted_duration}</p>
                        <p><b>Time</b>: {CurrentCall?.start_time ? moment(new Date(parseInt(CurrentCall?.start_time))).format("YYYY-MM-DD hh:mm:ss a") : ""}</p>
                        <p><b>Type</b>: {CurrentCall?.formatted_call_type?.replace("w/ Transcript", "")}</p>
                        <p><b>Source</b>: {CurrentCall?.formatted_tracking_source}</p>
                        <p><b>Play</b>: {CurrentCall?.recording_player && (
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              window.open(
                                CurrentCall?.recording_player?.replace(".mp3", "stream.mp3"),
                                "_blank",
                                "location=yes,height=400,width=700,scrollbars=yes,status=yes"
                              );
                            }}
                          >
                            Play
                          </a>
                        ) ||
                          ""}</p>
                        <p><b>Download</b>: {CurrentCall?.recording_player && (
                          <a href={CurrentCall?.recording_player?.replace(".us-east-1", "")} download>
                            Download
                          </a>
                        ) ||
                          ""}</p>
                        <p><b>Call Summary</b>: {CurrentCall?.call_summary}</p>
                        <p><b>Value</b>: {CurrentCall?.value}</p>
                      </div>
                    )}
                    <div style={{ clear: "both", height: "30px" }}></div>
                    <div style={{ float: "left", textAlign: "left" }}>
                      {users?.listCustomerUsers?.map(({ id, first_name, last_name }) => {
                        if (CurrentActivity?.users_id === id)
                          return (<div style={{ display: "block" }}><b>Created By</b>:  {first_name} {last_name}<br /></div>)
                      })}
                      {users?.listCustomerUsers?.map(({ id, first_name, last_name }) => {
                        if (CurrentActivity?.assigned_to === id)
                          return (<div style={{ display: "block" }}><b>Assigned To</b>:  {first_name} {last_name}<br /></div>)
                      })}
                      {CurrentActivity?.activity_type === "Job" &&
                        CRMJob?.filter((CurrentJob) => CurrentJob?.id === CurrentActivity?.job_id)?.map(CurrentJob => {
                          return (<div style={{ display: "block" }}><b>Products</b>: {CurrentJob?.products?.split(",")?.map(CurrentProduct => {
                            let ReturnedProducts = CRMProducts?.map(({ id, product_name }) => {
                              if (CurrentProduct === '' + id) {
                                return product_name
                              }
                            })
                            return ReturnedProducts?.filter(Boolean);
                          }).join(", ")} </div>)
                        }
                        )}
                      {CurrentActivity?.activity_type === "Job" &&
                        CRMJob?.filter((CurrentJob) => CurrentJob?.id === CurrentActivity?.job_id)?.map(CurrentJob => {
                          return (<div style={{ display: "block" }}><b>Services</b>: {CurrentJob?.services?.split(",")?.map(CurrentService => {
                            let ReturnedServices = CRMServices?.map(({ id, service_name }) => {
                              if (CurrentService === '' + id) {
                                return service_name
                              }
                            })
                            return ReturnedServices?.filter(Boolean);
                          }).join(", ")} </div>)
                        }
                        )}
                      {CurrentActivity?.activity_type === "Job" &&
                        CRMJob?.filter((CurrentJob) => CurrentJob?.id === CurrentActivity?.job_id)?.map(CurrentJob => {
                          return (<div style={{ display: "block" }}><b>Value</b>: {CurrentJob?.job_value} </div>)
                        }
                        )}
                      {CurrentActivity?.activity_type === "Job" &&
                        CRMJob?.filter((CurrentJob) => CurrentJob?.id === CurrentActivity?.job_id)?.map(CurrentJob => {
                          return (<div style={{ display: "block" }}><b>Scheduled</b>: {CurrentJob?.job_scheduled_date ? moment(new Date(parseInt(CurrentJob?.job_scheduled_date))).utc().format("MM-DD-YYYY, h:mm:ss A") : "NA"} </div>)
                        }
                        )}
                      {CurrentActivity?.activity_type === "Job" &&
                        CRMJob?.filter((CurrentJob) => CurrentJob?.id === CurrentActivity?.job_id)?.map(CurrentJob => {
                          return (<div style={{ display: "block" }}><b>Rescheduled</b>: {CurrentJob?.job_rescheduled_date ? moment(new Date(parseInt(CurrentJob?.job_rescheduled_date))).utc().format("MM-DD-YYYY, h:mm:ss A") : "NA"} </div>)
                        }
                        )}
                      {CurrentActivity?.activity_type === "Job" &&
                        CRMJob?.filter((CurrentJob) => CurrentJob?.id === CurrentActivity?.job_id)?.map(CurrentJob => {
                          return CRMPeople?.map(({ id, first_name, last_name }) => {
                            if (CurrentJob?.people_id === id)
                              return (<div style={{ display: "block" }}><b>People</b>:  {first_name} {last_name}<div style={{ clear: "both", height: "30px" }}></div></div>)
                          })
                        }
                        )}
                      {CurrentActivity?.activity_type === "Photo" &&
                        CRMPhotoGallery?.filter((CurrenGallery) => CurrenGallery?.id === CurrentActivity?.photo_gallery_id)?.map(CurrenGallery => {
                          return CRMPeople?.map(({ id, first_name, last_name }) => {
                            if (CurrenGallery?.people_id === id)
                              return (<div style={{ display: "block" }}><b>People</b>:  {first_name} {last_name}<div style={{ clear: "both", height: "30px" }}></div></div>)
                          })
                        }
                        )}
                      {CurrentActivity?.activity_type === "Photo" &&
                        CRMPhotoGallery?.filter((CurrenGallery) => CurrenGallery?.id === CurrentActivity?.photo_gallery_id)?.map(CurrenGallery => {
                          return CRMJob?.map(({ photo_gallery_id, job_name }) => {
                            if (CurrenGallery?.id === photo_gallery_id)
                              return (<div style={{ display: "block" }}><b>Job</b>:  {job_name}<div style={{ clear: "both", height: "30px" }}></div></div>)
                          })
                        }
                        )}
                      {CurrentActivity?.activity_date !== null ? <div><b>Submitted At</b>: {moment(new Date(parseInt(CurrentActivity?.activity_date))).format("MM-DD-YYYY, h:mm:ss A")}</div> : ""}
                      <div style={{ clear: "both", height: "30px" }}></div>

                      {CurrentActivity?.activity_type === "Document" && (
                        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                          <div style={{ textAlign: "left" }}>
                            <a target="_blank" href={CRMDocument?.filter((CurrentDocument) => CurrentDocument?.id === CurrentActivity?.document_id)?.map(CurrentDocument => { return CurrentDocument?.documents_url })}>View Document</a>
                          </div>
                        </span>
                      )}
                      {CurrentActivity?.activity_type === "Contract" && (
                        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                          <div style={{ textAlign: "left" }}>
                            <a target="_blank" href={CRMContract?.filter((CurrentContract) => CurrentContract?.id === CurrentActivity?.contract_id)?.map(CurrentContract => { return CurrentContract?.contract_url })}>View Contract</a>
                          </div>
                        </span>
                      )}
                      {CurrentActivity?.activity_type === "Proposal" && (
                        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                          <div style={{ textAlign: "left" }}>
                            <a target="_blank" href={CRMProposal?.filter((CurrentProposal) => CurrentProposal?.id === CurrentActivity?.proposal_id)?.map(CurrentProposal => { return CurrentProposal?.proposal_url })}>View Proposal</a>
                          </div>
                        </span>
                      )}
                      {CurrentActivity?.activity_type === "Onboarding Sent to Customer" && (
                        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                          <div style={{ textAlign: "left" }}>
                            <a target="_blank" href={CRMContract?.filter((CurrentContract) => CurrentContract?.id === CurrentActivity?.contract_id)?.map(CurrentContract => { return CurrentContract?.contract_url })}>View Contract</a>
                          </div>
                        </span>
                      )}
                      {CurrentActivity?.activity_type === "Customer Submitted Onboarding" && (
                        <span style={{ fontSize: "25px", float: "left", textAlign: "left" }}>
                          <div style={{ textAlign: "left" }}>
                            <a target="_blank" href={CRMContract?.filter((CurrentContract) => CurrentContract?.id === CurrentActivity?.contract_id)?.map(CurrentContract => { return CurrentContract?.contract_url })}>View Contract</a>
                          </div>
                        </span>
                      )}
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    {CurrentActivity?.activity_type === "Call" && (
                      <TimelineDot style={{ backgroundColor: "#00385D" }}>
                        <PhoneOutlinedIcon style={{ backgroundColor: "#00385D" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Email" && (
                      <TimelineDot style={{ backgroundColor: "#FF3C00" }}>
                        <EmailOutlinedIcon style={{ backgroundColor: "#FF3C00" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Text" && (
                      <TimelineDot style={{ backgroundColor: "#FDBB30" }}>
                        <SmartphoneOutlinedIcon style={{ backgroundColor: "#FDBB30" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Meeting" && (
                      <TimelineDot style={{ backgroundColor: "green" }}>
                        <GroupAddOutlinedIcon style={{ backgroundColor: "green" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Task" && (
                      <TimelineDot style={{ backgroundColor: "#DA70D6" }}>
                        <TaskAltOutlinedIcon style={{ backgroundColor: "#DA70D6" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Deadline" && (
                      <TimelineDot style={{ backgroundColor: "#32CD32" }}>
                        <MenuOutlinedIcon style={{ backgroundColor: "#32CD32" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Document" && (
                      <TimelineDot style={{ backgroundColor: "#FF10F0" }}>
                        <UploadFileOutlinedIcon style={{ backgroundColor: "#FF10F0" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Job" && (
                      <TimelineDot style={{ backgroundColor: "#A4A9AD" }}>
                        <WorkOutlineOutlinedIcon style={{ backgroundColor: "#A4A9AD" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Note" && (
                      <TimelineDot style={{ backgroundColor: "#BB0000" }}>
                        <NoteOutlinedIcon style={{ backgroundColor: "#BB0000" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Photo" && (
                      <TimelineDot style={{ backgroundColor: "#6B5B95" }}>
                        <PhotoCameraOutlinedIcon style={{ backgroundColor: "#6B5B95" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Estimate" && (
                      <TimelineDot style={{ backgroundColor: "#9FE2BF" }}>
                        <LocalActivityOutlined style={{ backgroundColor: "#9FE2BF" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Contract" && (
                      <TimelineDot style={{ backgroundColor: "#F7CAC9" }}>
                        <TaskOutlinedIcon style={{ backgroundColor: "#F7CAC9" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Proposal" && (
                      <TimelineDot style={{ backgroundColor: "#92A8D1" }}>
                        <ArticleOutlinedIcon style={{ backgroundColor: "#92A8D1" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Inbound Call" && (
                      <TimelineDot style={{ backgroundColor: "#D65076" }}>
                        <ContactPhoneOutlinedIcon style={{ backgroundColor: "#D65076" }} />
                      </TimelineDot>
                    )}
                    {CurrentActivity?.activity_type === "Inbound Form" && (
                      <TimelineDot style={{ backgroundColor: "#D65076" }}>
                        <ContactMailOutlinedIcon style={{ backgroundColor: "#D65076" }} />
                      </TimelineDot>
                    )}
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography component="span">
                      <div style={{ clear: "both", height: "30px" }}></div>
                      {CurrentActivity?.activity_type === "Inbound Form" && FormData?.filter(CurrentForm => CurrentForm?.id === CurrentActivity?.activity_description?.split(":")[1])?.map(CurrentForm =>
                        <div>
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <p><b>Note</b>: {CurrentForm?.note?.length > 20 ? CurrentForm?.note.substring(0, 19) + "..." : CurrentForm?.note}</p>
                        </div>
                      )}
                      {CurrentActivity?.activity_type === "Inbound Call" && CallData?.filter(CurrentCall => CurrentCall?.id === CurrentActivity?.activity_description?.split(":")[1])?.map(CurrentCall =>
                        <div style={{ color: "#000" }}>
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <p><b>Note</b>: {CurrentCall?.note}</p>
                        </div>
                      )}
                    </Typography>
                    <Typography>{CurrentActivity?.activity_type !== "Inbound Call" && CurrentActivity?.activity_type !== "Inbound Form" ? <RichTextEditor key={CurrentActivity["activity_type"]?.length + index} current={CurrentActivity} field={"activity_notes"} readonly={true} /> : ""}</Typography>
                  </TimelineContent>
                </TimelineItem>
                <hr style={{ color: "grey", borderColor: "grey", borderWidth: 3 }} />
              </>
            )
          }
        })
        }

        {!NoteFilterImageSelected && !JobFilterImageSelected && !UploadFilterImageSelected &&
          !DeadlineFilterImageSelected && !TaskFilterImageSelected && !MeetingFilterImageSelected &&
          !CallFilterImageSelected && !TextFilterImageSelected && !EmailFilterImageSelected &&
          !PhotoFilterImageSelected && !ContractFilterImageSelected && !ProposalFilterImageSelected &&
          !InboundCallsFilterImageSelected && !InboundFormsFilterImageSelected && !EstimateFilterImageSelected &&
          CRMActivity?.length > 20 ?
          <a href="#" onClick={e => {
            e.preventDefault();
            if (showMoreActivity) {
              setshowMoreActivity(false);
              
            } else {
              setshowMoreActivity(true)
            }
          }}>
            {showMoreActivity ? "See Less..." : "See More..."}
          </a>
          : ""}
      </Timeline>
    </div>
  )
};

export default LatestActivity;